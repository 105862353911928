export const logoutUser = async() => { 
    const bodyobj = {
      token: localStorage.getItem("token")
    }
    try {
      const response = await fetch(`/api/Customer/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyobj)
      });

      if (response?.status === 401) {
        window.location.href = "/login"
      }

      const data = await response.json();
      if (data?.responseCode === 200) {
        localStorage.setItem("token", "")
        window.location.href = "/login"
      }
    } catch (error) {
    }
  }